html {
  color-scheme: dark;
  font-size: min(max(16px, 4vw), 18px);
}

body,
#app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  background-color: #141312;
  color: #b5b5b5;

  /* font-family: 'Montserrat', sans-serif; */
  font-family: 'Fira Sans', Verdana, sans-serif;
}

#app {
  padding-top: 2vh;
}

a {
  text-decoration: none;
  color: #b5b5b5;
}

a:hover {
  color: #fff;
}

.time {
  font-size: 3em;
}

.timer-wrapper > div {
  margin: auto;
}
