footer {
  margin-top: auto;
  font-size: 0.75rem;
}

.footer__img--wrapper {
  position: relative;
}

.footer__img {
  width: 100%;

  object-fit: cover;
  max-height: 25vh;

  position: relative;

  filter: brightness(0.7);
  -webkit-mask-image: linear-gradient(to top, black 66%, transparent);
  -webkit-mask-image: linear-gradient(
    to top,
    hsl(0, 0%, 0%) 0%,
    hsla(0, 0%, 0%, 0.738) 19%,
    hsla(0, 0%, 0%, 0.541) 34%,
    hsla(0, 0%, 0%, 0.382) 47%,
    hsla(0, 0%, 0%, 0.278) 56.5%,
    hsla(0, 0%, 0%, 0.194) 65%,
    hsla(0, 0%, 0%, 0.126) 73%,
    hsla(0, 0%, 0%, 0.075) 80.2%,
    hsla(0, 0%, 0%, 0.042) 86.1%,
    hsla(0, 0%, 0%, 0.021) 91%,
    hsla(0, 0%, 0%, 0.008) 95.2%,
    hsla(0, 0%, 0%, 0.002) 98.2%,
    hsla(0, 0%, 0%, 0) 100%
  );
  mask-image: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
}

.footer__img--wrapper:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background-image: linear-gradient(90deg, transparent, #b5b5b5, transparent);
}

.footer--container {
  padding: 2em;
}
.footer--links svg {
  margin: 0 15px;
  height: 1.5em;
  width: 1.5em;
}
